import axios from 'axios';
import { API_ROOT } from '../config';

const axiosInstance = axios.create();

export const injextAxiosHeaders = () => {
  const accessToken = localStorage.getItem('accessToken');

  if (accessToken) axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

axiosInstance.defaults.baseURL = API_ROOT;

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Algo salió mal')
);

injextAxiosHeaders();

export default axiosInstance;
