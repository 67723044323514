import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // AUTHENTICATION
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      )
    },

    // DASHBOARD
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Dashboard />
        }
      ]
    },

    // PROPERTIES
    {
      path: 'properties',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'list',
          element: <PropertiesList />
        },
        {
          path: 'edit/:propertyId',
          element: <PropertyCreator />
        },
        {
          path: 'create',
          element: <PropertyCreator />
        }
      ]
    },

    // FAVORITES
    {
      path: 'favorites',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: 'list', element: <Favorites /> }]
    },

    // POSTS
    {
      path: 'posts',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'list', element: <Posts /> },
        {
          path: 'edit/:postId',
          element: <PostCreator />
        },
        {
          path: 'create',
          element: <PostCreator />
        }
      ]
    },

    // CITIES
    {
      path: 'cities',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: 'list', element: <CitiesList /> }]
    },

    // AGENCIES
    {
      path: 'agencies',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'list', element: <AgenciesList /> },
        { path: ':agencyId/members', element: <AgencyMembers /> }
      ]
    },

    // SUBURBS
    {
      path: 'suburbs',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: 'list', element: <SuburbsList /> }]
    },

    // SETTINGS
    {
      path: 'settings',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Settings />
        }
      ]
    },

    {
      path: '/',
      element: <Navigate to="/dashboard" />
    },
    { path: '*', element: <NotFound /> }
  ]);
}

// Authentication
const Login = Loadable(lazy(() => import('../pages/login')));

// Settings
const Settings = Loadable(lazy(() => import('../pages/settings')));
const Dashboard = Loadable(lazy(() => import('../pages/dashboard')));
const PropertiesList = Loadable(lazy(() => import('../pages/properties/list')));
const PropertyCreator = Loadable(lazy(() => import('../pages/properties/create')));
const PostCreator = Loadable(lazy(() => import('../pages/posts/create')));

const AgenciesList = Loadable(lazy(() => import('../pages/agencies')));
const AgencyMembers = Loadable(lazy(() => import('../pages/agencies/AgencyMembers')));
const CitiesList = Loadable(lazy(() => import('../pages/cities')));
const Favorites = Loadable(lazy(() => import('../pages/favorites')));
const Posts = Loadable(lazy(() => import('../pages/posts/list')));
const SuburbsList = Loadable(lazy(() => import('../pages/suburbs')));
const NotFound = Loadable(lazy(() => import('../pages/page404')));
