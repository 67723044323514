import { createSlice } from '@reduxjs/toolkit';
// utils
import propertiesApi from '../../api/properties';
import { ENTITY_STATUS } from '../../constants';

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  fetched: false,
  error: false,
  list: []
};

const slice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },

    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    getPropertiesSuccess(state, action) {
      state.loading = false;
      state.fetched = true;
      state.list = action.payload;
    },

    propertyDeleted(state, action) {
      state.loading = false;
      state.list = state.list.filter((item) => item.id !== action.payload);
    },

    propertyUpdated(state, action) {
      state.loading = false;
      const { id, data } = action.payload;
      const index = state.list.findIndex((item) => item.id === id);
      if (index !== -1) state.list[index] = { ...state.list[index], ...data };
    },

    propertySaved(state, action) {
      state.loading = false;
      state.list.unshift(action.payload);
    },

    propertyPublished(state, action) {
      state.loading = false;
      const id = action.payload;
      const statusId = ENTITY_STATUS.activo;
      const status = Object.keys(ENTITY_STATUS).find((item) => item === 'active');
      const index = state.list.findIndex((item) => item.id === id);
      if (index !== -1) state.list[index] = { ...state.list[index], status_id: statusId, status };
    },

    propertyDisabled(state, action) {
      state.loading = false;
      const id = action.payload;
      const statusId = ENTITY_STATUS.deshabilitado;
      const status = Object.keys(ENTITY_STATUS).find((item) => item === 'disabled');
      const index = state.list.findIndex((item) => item.id === id);
      if (index !== -1) state.list[index] = { ...state.list[index], status_id: statusId, status };
    },

    propertySold(state, action) {
      state.loading = false;
      const id = action.payload;
      const statusId = ENTITY_STATUS.vendido;
      const status = Object.keys(ENTITY_STATUS).find((item) => item === 'sold');
      const index = state.list.findIndex((item) => item.id === id);
      if (index !== -1) state.list[index] = { ...state.list[index], status_id: statusId, status };
    },

    propertyRollBack(state, action) {
      state.loading = false;
      state.list = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  getPropertiesSuccess,
  propertyDeleted,
  propertyRollBack,
  propertyUpdated,
  propertySaved,
  propertyPublished,
  propertyDisabled,
  propertySold
} = slice.actions;

// ----------------------------------------------------------------------

export function getProperties() {
  return async (dispatch, getState) => {
    const {
      properties: { fetched, list }
    } = getState();

    if (fetched) return list;

    try {
      dispatch(startLoading());
      const response = await propertiesApi.getAll();
      dispatch(getPropertiesSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function updateProperty(id, data) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await propertiesApi.update(id, data);
      dispatch(propertyUpdated({ id, data }));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function saveProperty(data) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await propertiesApi.save(data);
      dispatch(propertySaved(response.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function removeProperty(id) {
  return async (dispatch, getState) => {
    // optimistic approach
    const {
      properties: { list }
    } = { ...getState() };
    dispatch(propertyDeleted(id));

    try {
      dispatch(startLoading());
      const response = await propertiesApi.remove(id);
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      dispatch(propertyRollBack(list));
      throw error;
    }
  };
}

export function publishProperty(id) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await propertiesApi.publish(id);
      dispatch(propertyPublished(Number(id)));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function disableProperty(id) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await propertiesApi.disable(id);
      dispatch(propertyDisabled(Number(id)));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function markAsSold(id) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await propertiesApi.setAsSold(id);
      dispatch(propertySold(Number(id)));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function setupAmenity(propertyId, data) {
  return async (dispatch) => {
    try {
      const response = await propertiesApi.setAmenity(propertyId, data);
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
