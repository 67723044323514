import axios from '../utils/axios';
import { API_ROOT } from '../config';

const getAll = () => axios.get('/posts');

const update = (id, data) => axios.patch(`/posts/${id}`, data);

const save = (data) => axios.post(`/posts`, data);

// using AI
const generateContent = (prompt) => axios.post(`/posts/ai`, { prompt });

const remove = (postId) => axios.delete(`/posts/${postId}`);

const disable = (postId) => axios.put(`/posts/disable/${postId}`);

const publish = (postId) => axios.put(`/posts/publish/${postId}`);

const sortPosts = (order) => axios.post(`/posts/sort`, { order });

const uploadCover = (cover) => {
  const data = new FormData();
  data.append('cover', cover);

  return fetch(`${API_ROOT}/posts/uploader/setup/cover`, {
    method: 'POST',
    body: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};

const uploadImage = (image) => uploadCover(image);

export default {
  getAll,
  save,
  update,
  uploadCover,
  remove,
  disable,
  publish,
  generateContent,
  uploadImage,
  sortPosts
};
