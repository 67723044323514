import axios from '../utils/axios';

const getAll = () => axios.get('/agencies');

const getMembers = (id) => axios.get(`/agencies/${id}/members`);

const saveMember = (id, memberData) => axios.put(`/agencies/${id}/members`, memberData);

const updateMember = (agencyId, memberId, memberData) =>
  axios.put(`/agencies/${agencyId}/members/${memberId}`, memberData);

const update = (id, data) => axios.put(`/agencies/${id}`, data);

const save = (data) => axios.post(`/agencies`, data);

const remove = (id) => axios.delete(`/agencies/${id}`);

export default {
  getMembers,
  getAll,
  update,
  remove,
  save,
  saveMember,
  updateMember
};
