import axios from '../utils/axios';
import { ENTITY_STATUS } from '../constants';

export const uploadPropertyPhotosUrl = '/properties/uploader/setup/gallery/upload';

const getAll = () => axios.get(`/properties`);

const getByBrokerId = (brokerId) => axios.get(`/properties?brokerId=${brokerId}`);

const remove = (id) => axios.delete(`/properties/${id}`);

const update = (id, data) => axios.patch(`/properties/${id}`, data);

const save = (data) => axios.post(`/properties`, data);

const orderPhotos = (id, data) => axios.post(`/properties/uploader/setup/gallery/order/${id}`, data);

const removePhoto = (propertyId, photoId) =>
  axios.post(`/properties/uploader/setup/gallery/remove/${propertyId}/${photoId}`);

const getPhotos = (id) => axios.get(`/properties/uploader/setup/gallery/get/${id}`);

const setAmenity = (propertyId, data) => axios.post(`/properties/setup/amenities/set/${propertyId}`, data);

const publish = (id) => axios.put(`/properties/publish/${id}`);

const disable = (id) => axios.put(`/properties/disable/${id}`);

const setAsSold = (id) => axios.patch(`/properties/${id}`, { status_id: ENTITY_STATUS.vendido });

export default {
  getAll,
  remove,
  update,
  save,
  orderPhotos,
  removePhoto,
  getPhotos,
  setAmenity,
  publish,
  disable,
  setAsSold,
  getByBrokerId
};
