import { createSlice } from '@reduxjs/toolkit';
// utils
import favoritesApi from '../../api/favorites';

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  fetched: false,
  error: false,
  list: []
};

const slice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },

    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    getFavoritesSuccess(state, action) {
      state.loading = false;
      state.fetched = true;
      state.list = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, hasError, getFavoritesSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getFavorites() {
  return async (dispatch, getState) => {
    const {
      favorites: { fetched, list }
    } = getState();

    if (fetched) return list;

    try {
      dispatch(startLoading());
      const response = await favoritesApi.getAll();
      dispatch(getFavoritesSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
