export const GENERIC_ERROR = 'An unexpected error ocurred, please contact the admin';

export const DEFAULT_PROPERTY_IMAGE = 'https://morancorealty.com/img/properties/default.jpg';

export const PROPERTY_TYPES = [
  { label: 'Local Comercial', id: 1 },
  { label: 'Casa', id: 2 },
  { label: 'Terreno', id: 3 },
  { label: 'Departamento', id: 4 },
  { label: 'Hotel', id: 5 },
  { label: 'Rancho', id: 6 }
];

export const USER_ROLES = ['admin', 'sales_agent'];

export const ENTITY_STATUS = {
  deshabilitado: 0,
  activo: 1,
  basurero: 2,
  borrador: 3,
  vendido: 4
};

export const PROPERTY_STATUS_ACTIVE = 1;
export const PROPERTY_STATUS_SOLD = 4;
export const CURRENCIES = ['MXN', 'USD'];

export const AGENCY_TYPES = ['Agency', 'Constructor'];

export const DEFAULT_CURRENCY = 'MXN';

export const POST_CATEGORY_BLOG = 1;
export const POST_CATEGORY_PODCAST = 2;
export const POST_CATEGORY_REALITY_SHOW = 3;

export const STATES_FROM_MEXICO = [
  { id: 201, name: 'Aguascalientes' },
  { id: 202, name: 'Baja California' },
  { id: 203, name: 'Baja California Sur' },
  { id: 204, name: 'Campeche' },
  { id: 205, name: 'Chiapas' },
  { id: 206, name: 'Chihuahua' },
  { id: 207, name: 'Coahuila' },
  { id: 208, name: 'Colima' },
  { id: 209, name: 'Ciudad de México' },
  { id: 210, name: 'Durango' },
  { id: 211, name: 'Estado de México' },
  { id: 212, name: 'Guanajuato' },
  { id: 213, name: 'Guerrero' },
  { id: 214, name: 'Hidalgo' },
  { id: 215, name: 'Jalisco' },
  { id: 216, name: 'Michoacán' },
  { id: 217, name: 'Morelos' },
  { id: 218, name: 'Nayarit' },
  { id: 219, name: 'Nuevo León' },
  { id: 220, name: 'Oaxaca' },
  { id: 221, name: 'Puebla' },
  { id: 222, name: 'Querétaro' },
  { id: 223, name: 'Quintana Roo' },
  { id: 224, name: 'San Luis Potosí' },
  { id: 225, name: 'Sinaloa' },
  { id: 226, name: 'Sonora' },
  { id: 227, name: 'Tabasco' },
  { id: 228, name: 'Tamaulipas' },
  { id: 229, name: 'Tlaxcala' },
  { id: 230, name: 'Veracruz' },
  { id: 231, name: 'Yucatán' },
  { id: 232, name: 'Zacatecas' }
];
