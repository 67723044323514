import axios from '../utils/axios';

const getByCity = (cityId) => axios.get(`/suburbs/${cityId}`);

const getAll = () => axios.get('/suburbs');

const update = (id, data) => axios.put(`/suburbs/${id}`, data);

const save = (data) => axios.post(`/suburbs`, data);

const remove = (id) => axios.delete(`/suburbs/${id}`);

export default {
  getByCity,
  getAll,
  update,
  remove,
  save
};
