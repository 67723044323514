import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices

import propertiesReducer from './slices/properties';
import agenciesReducer from './slices/agencies';
import citiesReducer from './slices/cities';
import suburbsReducer from './slices/suburbs';
import favoritesReducer from './slices/favorites';
import postsReducer from './slices/posts';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  properties: propertiesReducer,
  agencies: agenciesReducer,
  cities: citiesReducer,
  suburbs: suburbsReducer,
  favorites: favoritesReducer,
  posts: postsReducer
});

export { rootPersistConfig, rootReducer };
