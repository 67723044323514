import { createSlice } from '@reduxjs/toolkit';
// utils
import postsApi from '../../api/posts';
import { ENTITY_STATUS } from '../../constants';
import { REACT_APP_UPLOADS_DIR_URL } from '../../config';

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  fetched: false,
  error: false,
  list: []
};

const slice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },

    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    gotPostSuccess(state, action) {
      state.loading = false;
      state.fetched = true;
      state.list = action.payload;
    },

    postSaved(state, action) {
      state.loading = false;
      state.list.unshift(action.payload);
    },

    postsSorted(state, action) {
      state.loading = false;
      state.list = action.payload;
    },

    postUpdated(state, action) {
      state.loading = false;
      const { id, data } = action.payload;
      const index = state.list.findIndex((item) => item.id === id);
      if (index !== -1) state.list[index] = { ...state.list[index], ...data };
    },

    postRemoved(state, action) {
      state.loading = false;
      state.list = state.list.filter((item) => item.id !== action.payload);
    },

    postDisabled(state, action) {
      state.loading = false;
      const id = action.payload;
      const statusId = ENTITY_STATUS.deshabilitado;
      const status = Object.keys(ENTITY_STATUS).find((item) => item === 'disabled');
      const index = state.list.findIndex((item) => item.id === id);
      if (index !== -1) state.list[index] = { ...state.list[index], status_id: statusId, status };
    },

    postPublished(state, action) {
      state.loading = false;
      const id = action.payload;
      const statusId = ENTITY_STATUS.activo;
      const status = Object.keys(ENTITY_STATUS).find((item) => item === 'active');
      const index = state.list.findIndex((item) => item.id === id);
      if (index !== -1) state.list[index] = { ...state.list[index], status_id: statusId, status };
    },

    postRollBack(state, action) {
      state.loading = false;
      state.list = action.payload;
    },

    coverUploaded(state) {
      state.loading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  gotPostSuccess,
  postsSorted,
  postSaved,
  postUpdated,
  coverUploaded,
  postRemoved,
  postDisabled,
  postPublished,
  postRollBack
} = slice.actions;

// ----------------------------------------------------------------------

// prepend uploads URL to image prop so frontend can render images properly
export const getImageUrl = (imagePath) => `${REACT_APP_UPLOADS_DIR_URL}${imagePath}`;

// ----------------------------------------------------------------------

export function getPosts() {
  return async (dispatch, getState) => {
    const {
      posts: { fetched, list }
    } = getState();

    if (fetched) return list;

    try {
      dispatch(startLoading());
      const response = await postsApi.getAll();
      dispatch(gotPostSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function updatePost(id, postData) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await postsApi.update(id, postData);
      const data = { ...response.data };
      if (data?.image) {
        data.image = getImageUrl(data.image);
      }

      dispatch(postUpdated({ id, data }));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function savePost(postData) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await postsApi.save(postData);
      const data = { ...response.data };
      if (data?.image) {
        data.image = getImageUrl(data.image);
      }

      dispatch(postSaved(data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function removePost(id) {
  return async (dispatch, getState) => {
    // optimistic approach
    const {
      posts: { list }
    } = { ...getState() };
    dispatch(postRemoved(id));

    try {
      dispatch(startLoading());
      const response = await postsApi.remove(id);
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      dispatch(postRollBack(list));
      throw error;
    }
  };
}

export function disablePost(id) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await postsApi.disable(id);
      dispatch(postDisabled(Number(id)));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function publishPost(id) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await postsApi.publish(id);
      dispatch(postPublished(Number(id)));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

// this call is performed using fetch api
export function uploadCover(cover) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await postsApi.uploadCover(cover);
      const json = await response.json();
      dispatch(coverUploaded());
      return json;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function sortPosts({ results, rowsPerPage, page }) {
  return async (dispatch, getState) => {
    // optimistic approach
    const {
      posts: { list }
    } = { ...getState() };

    try {
      const { source, destination } = results;
      if (!destination) return;
      const selectedRow = list[source.index];
      const clonedList = [...list].filter((item) => item.id !== Number(selectedRow.id));
      clonedList.splice(destination.index, 0, selectedRow);
      dispatch(postsSorted(clonedList));

      // update order in database
      const newOrder = clonedList.map((item) => item.id);
      await postsApi.sortPosts(newOrder);
    } catch (error) {
      dispatch(hasError(error));
      dispatch(postRollBack(list));
      throw error;
    }
  };
}
