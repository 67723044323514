import { createSlice } from '@reduxjs/toolkit';
// utils
import agenciesApi from '../../api/agencies';
import brokersApi from '../../api/brokers';

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  fetched: false,
  error: false,
  list: []
};

const slice = createSlice({
  name: 'agencies',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },

    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    getAgenciesSuccess(state, action) {
      state.loading = false;
      state.fetched = true;
      state.list = action.payload;
    },

    agencyUpdated(state, action) {
      state.loading = false;
      const index = state.list.findIndex((item) => item.id === action.payload.id);
      const members = state.list[index]?.members || [];
      if (index !== -1) {
        state.list[index] = action.payload;
        state.list[index].members = members;
      }
    },

    brokerUpdated(state, action) {
      state.loading = false;
      const { agency_id: agencyId, ...brokerData } = action.payload;

      console.log(action.payload);
      const indexAgency = state.list.findIndex((item) => item.id === agencyId);
      if (indexAgency !== -1) {
        const members = state.list[indexAgency]?.members || [];
        const memberIndex = members.findIndex((m) => m.id === brokerData.id);
        if (memberIndex !== -1) {
          state.list[indexAgency].members[memberIndex] = { ...brokerData };
        }
      }
    },
    brokerSaved(state, action) {
      state.loading = false;
      const { agency_id: agencyId, ...brokerData } = action.payload;
      const agency = state.list.find((item) => item.id === agencyId);
      if (agency) {
        const members = agency?.members ? [...agency.members, brokerData] : [brokerData];
        agency.members = members;
      }
    },

    agencySaved(state, action) {
      state.loading = false;
      state.list.push(action.payload);
    },

    agencyRemoved(state, action) {
      state.loading = false;
      state.list = state.list.filter((item) => item.id !== action.payload);
    },

    agencyRollBack(state, action) {
      state.loading = false;
      state.list = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  hasError,
  getAgenciesSuccess,
  agencyUpdated,
  brokerUpdated,
  agencySaved,
  brokerSaved,
  agencyRemoved,
  agencyRollBack
} = slice.actions;

// ----------------------------------------------------------------------

export function getAgencies() {
  return async (dispatch, getState) => {
    const {
      agencies: { fetched, list }
    } = getState();

    if (fetched) return list;

    try {
      dispatch(startLoading());
      const response = await agenciesApi.getAll();
      dispatch(getAgenciesSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function updateAgency(id, data) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await agenciesApi.update(id, data);
      dispatch(
        agencyUpdated({
          id,
          ...data
        })
      );
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function saveAgency(data) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await agenciesApi.save(data);
      dispatch(agencySaved(response.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function saveBroker(brokerData) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await brokersApi.save(brokerData);
      dispatch(brokerSaved(response.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function updateBroker(brokerId, data) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await brokersApi.update(brokerId, data);
      dispatch(
        brokerUpdated({
          ...response.data,
          id: brokerId
        })
      );
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function removeCity(id) {
  return async (dispatch, getState) => {
    // optimistic approach
    const {
      cities: { list }
    } = { ...getState() };
    dispatch(agencyRemoved(id));

    try {
      dispatch(startLoading());
      const response = await agenciesApi.remove(id);
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      dispatch(agencyRollBack(list));
      throw error;
    }
  };
}
