import axios from '../utils/axios';

const getAll = () => axios.get('/brokers');

const getPropertyBrokers = (propertyId) => axios.get(`/properties/${propertyId}/brokers`);

const getAvailableBrokers = (propertyId) => axios.get(`/properties/${propertyId}/available-brokers`);

const saveBrokerToProperty = (propertyId, broker) => axios.patch(`/properties/${propertyId}/brokers`, broker);

const removeBrokerFromProperty = (propertyId, brokerId) =>
  axios.delete(`/properties/${propertyId}/brokers/${brokerId}`);

const update = (brokerId, data) => axios.put(`/brokers/${brokerId}`, data);

const save = (data) => axios.post(`/brokers`, data);

export default {
  getAll,
  saveBrokerToProperty,
  getPropertyBrokers,
  getAvailableBrokers,
  removeBrokerFromProperty,
  update,
  save
};
