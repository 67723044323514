// ----------------------------------------------------------------------

export const PATH = {
  root: '/',
  dashboard: '/dashboard',
  auth: {
    login: '/login',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password'
  },
  posts: {
    list: '/posts/list',
    edit: '/posts/edit',
    create: '/posts/create'
  },
  user: {
    profile: '/profile',
    settings: '/settings'
  },
  properties: {
    list: '/properties/list',
    edit: '/properties/edit',
    create: '/properties/create'
  },
  favorites: {
    list: '/favorites/list'
  },
  cities: {
    list: '/cities/list'
  },
  agencies: {
    root: '/agencies',
    list: '/agencies/list',
    members: '/agencies/{id}/members'
  },
  suburbs: {
    list: '/suburbs/list'
  },
  settings: {
    root: '/settings',
    general: '/settings/general'
  }
};
