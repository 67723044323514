import axios from '../utils/axios';

const getByState = (stateId) => axios.get(`/cities/${stateId}`);

const getAll = () => axios.get('/cities');

const update = (id, data) => axios.put(`/cities/${id}`, data);

const save = (data) => axios.post(`/cities`, data);

const remove = (id) => axios.delete(`/cities/${id}`);

export default {
  getByState,
  getAll,
  update,
  remove,
  save
};
