import { createSlice } from '@reduxjs/toolkit';
// utils
import citiesApi from '../../api/cities';

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  fetched: false,
  error: false,
  list: []
};

const slice = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },

    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    getCitiesSuccess(state, action) {
      state.loading = false;
      state.fetched = true;
      state.list = action.payload;
    },

    cityUpdated(state, action) {
      state.loading = false;
      const index = state.list.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) state.list[index] = action.payload;
    },

    citySaved(state, action) {
      state.loading = false;
      state.list.push(action.payload);
    },

    cityRemoved(state, action) {
      state.loading = false;
      state.list = state.list.filter((item) => item.id !== action.payload);
    },

    citiesRollBack(state, action) {
      state.loading = false;
      state.list = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, hasError, getCitiesSuccess, cityUpdated, citySaved, cityRemoved, citiesRollBack } =
  slice.actions;

// ----------------------------------------------------------------------

export function getCities() {
  return async (dispatch, getState) => {
    const {
      cities: { fetched, list }
    } = getState();

    if (fetched) return list;

    try {
      dispatch(startLoading());
      const response = await citiesApi.getAll();
      dispatch(getCitiesSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function updateCity(id, data) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await citiesApi.update(id, data);
      dispatch(
        cityUpdated({
          id,
          ...data
        })
      );
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function saveCity(data) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await citiesApi.save(data);
      dispatch(citySaved(response.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function removeCity(id) {
  return async (dispatch, getState) => {
    // optimistic approach
    const {
      cities: { list }
    } = { ...getState() };
    dispatch(cityRemoved(id));

    try {
      dispatch(startLoading());
      const response = await citiesApi.remove(id);
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      dispatch(citiesRollBack(list));
      throw error;
    }
  };
}
